import { Button, Tooltip } from '@kajabi/sage-react';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { localizedPriceFromMinorUnit, readableTextColor } from 'apps/commerce/popup_checkout/util';
// TODO: Move these into common checkout
import {
  OfferStatus,
  disabledCheckoutToolTipContent,
} from 'apps/commerce/popup_checkout/constants';

export const PayButton = ({ dueNowPriceInCents, disableSubmit, isPaymentInProgress }) => {
  const { t } = useTranslation();
  const { getValues, watch } = useFormContext();
  const { offer, price, offerPurchase, paymentMethods, checkoutTextOverride, checkoutPageColor } =
    getValues();
  const isFree = watch('isFree');

  // TODO: If we want more checkouts to support the sold out, expired, or draft button texts we will
  // need to separate them from being offer checkout only.
  const buttonText = useMemo(() => {
    if (checkoutTextOverride?.length > 0) return checkoutTextOverride;
    if (offerPurchase?.type === 'subscription') return t('form.already_subscribed');
    if (offer?.status === OfferStatus.SOLD_OUT) return t('form.sold_out');
    if (offer?.status === OfferStatus.EXPIRED) return t('form.expired');
    if (offer?.status === OfferStatus.DRAFT) return t('form.draft');

    if (isFree) return t('form.submit_free');
    const localizedDueNowPrice = localizedPriceFromMinorUnit(
      price.currency,
      dueNowPriceInCents,
      price.exponent,
    );
    return `${t('form.submit')} ${localizedDueNowPrice} ${price.currency.toUpperCase()}`;
  }, [price, offerPurchase, t, offer?.status, dueNowPriceInCents, isFree]); // esline-disable-line react-hooks/exhaustive-deps

  const shouldDisableButton =
    disableSubmit || offerPurchase?.type === 'subscription' || (!paymentMethods && !isFree);
  const shouldRenderTooltip = !paymentMethods && !isFree;

  const enabledStyle = {
    color: readableTextColor(checkoutPageColor),
    backgroundColor: checkoutPageColor,
  };

  // if there are no valid paymentMethods and the offer is not free,
  // show the disabled button with a tooltip
  return shouldRenderTooltip ? (
    <>
      <Tooltip.Element className="popup-submit-disabled" content={disabledCheckoutToolTipContent} />
      <Button
        className="popup-submit-disabled"
        data-testid="popup-submit-button"
        type="submit"
        disabled={true}
        loading={false}
        fullWidth
      >
        {buttonText}
      </Button>
    </>
  ) : (
    <Button
      data-testid="popup-submit-button"
      type="submit"
      disabled={shouldDisableButton}
      loading={isPaymentInProgress}
      fullWidth
      // the sage button component doesn't support arbitrary colors
      // via the color prop, so we have to use the style prop
      style={shouldDisableButton ? {} : enabledStyle}
    >
      {buttonText}
    </Button>
  );
};

PayButton.propTypes = {
  dueNowPriceInCents: PropTypes.number.isRequired,
  disableSubmit: PropTypes.bool.isRequired,
  isPaymentInProgress: PropTypes.bool.isRequired,
};

export default PayButton;
