import React from 'react';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import useToast from 'apps/commerce/common/checkout/hooks/useToast';

const PaymentDetails = ({ disabled }) => {
  const {
    getValues,
    setValue,
    clearErrors,
    trigger,
    formState: { errors },
  } = useFormContext();
  const { showToast } = useToast();
  const {
    paymentMethods,
    additionalPaymentMethods,
    site: { title },
  } = getValues();

  const changeHandler = (event) => {
    const isComplete = !!event.complete;
    if (isComplete) {
      setValue('isPaymentDetailComplete', true);
      // if there was an error, clear it and trigger validation
      if (errors.payment) {
        clearErrors('payment');
        trigger();
      }
    } else {
      setValue('isPaymentDetailComplete', false);
    }
    const provider = event.value?.type || 'card';
    setValue('paymentProvider', provider);
  };

  const additionalPaymentMethodAmountError = (error) => {
    if (!paymentMethods || paymentMethods.length === 0) {
      return false;
    }

    const amountErrors = ['amount_too_small', 'amount_too_large'];
    return amountErrors.includes(error.code);
  };

  // This is order-dependent. You must first reload the Stripe Element
  // and then set additionalPaymentMethods or it won't re-render
  const attemptReloadWithCardsOnly = () => {
    setValue('attemptReloadWithCardsOnly', true);
    setValue('additionalPaymentMethods', []);
  };

  const paymentElementErrorHandler = (error) => {
    if (additionalPaymentMethodAmountError(error)) {
      attemptReloadWithCardsOnly();
    } else {
      showToast({ title: t('messages.something_went_wrong'), type: 'danger' });
    }
  };

  return (
    <div data-testid="payment-element">
      <PaymentElement
        onChange={changeHandler}
        onLoadError={(event) => paymentElementErrorHandler(event.error)}
        // for some reason PaymentElement doesn't make itself look inactive when readOnly is set
        // so we add a CSS class to use opacity to make it look disabled
        options={{
          readOnly: disabled,
          layout: {
            defaultCollapsed: true,
            type:
              paymentMethods?.length + (additionalPaymentMethods?.length || 0) > 1
                ? 'accordion'
                : 'tabs',
          },
          business: {
            name: title,
          },
          fields: {
            billingDetails: {
              email: 'never',
            },
          },
          wallets: {
            googlePay: additionalPaymentMethods?.includes('google_pay') ? 'auto' : 'never',
            applePay: additionalPaymentMethods?.includes('apple_pay') ? 'auto' : 'never',
          },
        }}
        className={disabled ? 'stripe-element-disabled' : ''}
      />
    </div>
  );
};

PaymentDetails.propTypes = {
  disabled: PropTypes.bool.isRequired,
};

export default PaymentDetails;
