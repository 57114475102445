import React from 'react';
import { Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { SageTokens } from '@kajabi/sage-react';

const FALLBACK_FONTS =
  ' -apple-system, "BlinkMacSystemFont", "Segoe UI", "Roboto", "Helvetica Neue", "Arial", "Noto Sans", sans-serif';
const fontFamily = `"Circular", ${FALLBACK_FONTS}`;
const LETTER_SPACING = '-0.15px';

const STRIPE_APPEARANCE = {
  variables: {
    borderRadius: 'var(--pop-modal-border-radius-base, 10px)',
    colorText: SageTokens.COLOR_PALETTE.CHARCOAL_400,
    colorDanger: SageTokens.COLOR_PALETTE.RED_300,
    spacingGridColumn: '1rem',
    spacingGridRow: '1rem',
    fontWeightNormal: '500',
  },
  rules: {
    '.Label': {
      fontWeight: '600',
      fontSize: 'var(--sage-font-size-body)',
      marginBottom: '8px',
      fontFamily,
      letterSpacing: LETTER_SPACING,
    },
    '.TabLabel': {
      fontWeight: '600',
      fontFamily,
      letterSpacing: LETTER_SPACING,
    },
    '.Input': {
      letterSpacing: LETTER_SPACING,
      boxShadow: 'none',
      border: `var(--pop-modal-border-dark, 1px solid ${SageTokens.COLOR_PALETTE.GREY_400})`,
    },
    '.Input::placeholder': {
      color: SageTokens.COLOR_PALETTE.CHARCOAL_200,
    },
    '.Input--invalid': {
      boxShadow: 'none',
    },
    '.Error': {
      fontFamily,
      fontSize: 'var(--sage-font-size-body-sm)',
      fontWeight: '500',
      marginTop: '8px',
    },
    '.AccordionItem': {
      fontFamily,
      boxShadow: 'none',
      borderColor: `${SageTokens.COLOR_PALETTE.GREY_400}`,
    },
  },
};

const FONTS = [
  {
    family: 'Circular',
    src: 'url(https://sage.kajabi-cdn.com/fonts/circular/CircularXXWeb-Regular.woff2?v=1)',
    weight: '400',
  },
  {
    family: 'Circular',
    src: 'url(https://sage.kajabi-cdn.com/fonts/circular/CircularXXWeb-Book.woff2?v=1)',
    weight: '500',
  },
  {
    family: 'Circular',
    src: 'url(https://sage.kajabi-cdn.com/fonts/circular/CircularXXWeb-Bold.woff2?v=1)',
    weight: '700',
  },
];

interface CheckoutStripeElementsProps {
  stripe?: Stripe | PromiseLike<Stripe | null> | null;
  attemptReloadWithCardsOnly?: boolean;
  amountPriceInCents?: number;
  currency?: string;
  paymentMethods?: string[];
  additionalPaymentMethods?: string[];
  vendorAccountId?: string;
  children: React.ReactNode;
}

// TODO: Instead of passing these in it would be nice to useFormContext to read
// them but this requires consistency from invoice and popup checkout.
const CheckoutStripeElements = ({
  stripe,
  attemptReloadWithCardsOnly,
  amountPriceInCents,
  currency,
  paymentMethods,
  additionalPaymentMethods,
  vendorAccountId,
  children,
}: CheckoutStripeElementsProps) => {
  if (!stripe) {
    return <>{children}</>;
  }

  const amount = amountPriceInCents || 0;
  const options: any = {
    fonts: FONTS,
    appearance: STRIPE_APPEARANCE,
    mode: 'payment',
    // too low a value will cause the stripe element to fail, so set it to this
    // amount for now, it will be overridden before the actual element is displayed
    // The value depends on the settlement currency of the hero's stripe account
    amount: Math.max(amount, 100),
    currency: currency ? currency.toLowerCase() : undefined,
    paymentMethodTypes: additionalPaymentMethods
      ? [
          'card',
          ...additionalPaymentMethods.filter(
            // don't include apple_pay or google_pay in the list of payment methods
            // they're handled by the wallets parameter
            (method) => method !== 'apple_pay' && method !== 'google_pay',
          ),
        ]
      : ['card'],
    externalPaymentMethodTypes: paymentMethods?.includes('paypal') ? ['external_paypal'] : [],
  };
  if (vendorAccountId) {
    options.onBehalfOf = vendorAccountId;
  }

  return (
    // The value of the key here isn't important and is only necessary to force
    // the Element to re-mount
    <Elements key={attemptReloadWithCardsOnly ? '1' : '0'} stripe={stripe} options={options}>
      {children}
    </Elements>
  );
};

export default CheckoutStripeElements;
