import { emailRegex } from 'apps/commerce/common/checkout/constants';
import { majorUnitAmount } from './util';

export const OfferStatus = {
  AVAILABLE: 'available',
  EXPIRED: 'expired',
  SOLD_OUT: 'sold_out',
  DRAFT: 'draft',
};

export const disabledCheckoutToolTipContent =
  'Payments are paused on this site. Contact site owner for assistance.';
